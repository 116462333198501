/* eslint-disable react/display-name */
import { keyframes } from "@stitches/react";
import React, { useState } from "react";

import { Button } from "~src/designSystem/atoms/Button";
import { Text } from "~src/designSystem/atoms/Text";
import { View } from "~src/designSystem/atoms/View";
import { Flex } from "~src/designSystem/layout/Flex";
import { Spacer } from "~src/designSystem/layout/Spacer";
import { styled, t } from "~src/designSystem/theme";
import { useConnectDataSource } from "~src/shared/dataSources/connectDataSource/hooks/useConnectDataSource";
import { useOnDataSourceRedirectSuccessEffect } from "~src/shared/dataSources/useOnDataSourceRedirectSuccessEffect";
import { useVendorDataSources } from "~src/shared/dataSources/useVendorDataSources";
import { ICountryCode } from "~src/shared/types";

import { ContactSupport } from "./ContactSupport";
import { AnalyticsIcon } from "./icons/AnalyticsIcon";
import { LightningIcon } from "./icons/LightningIcon";
import { StatementIcon } from "./icons/StatementIcon";

type IProps = {
  country?: ICountryCode;
};

type IStatus = "syncing" | "in_review" | "results";

const Items = [
  {
    text: "Syncing",
    status: "syncing",
    getIcon: (isActive: boolean) => (isActive ? <LightningIcon fill="#fff" /> : <LightningIcon />),
  },
  {
    text: "In Review",
    status: "in_review",
    getIcon: (isActive: boolean) => (isActive ? <AnalyticsIcon fill="#fff" /> : <AnalyticsIcon />),
  },
  {
    text: "Results",
    status: "results",
    getIcon: (isActive: boolean) => (isActive ? <StatementIcon fill="#fff" /> : <StatementIcon />),
  },
];

export const BeingReviewed: React.FC<IProps> = ({ country }) => {
  const { refetch } = useVendorDataSources();

  const handleSuccess = React.useCallback(async (): Promise<void> => {
    await refetch();
  }, [refetch]);

  const openConnectDataSource = useConnectDataSource({
    onSuccess: handleSuccess,
    redirectPath: "/inbox",
    source: "being-reviewed-add-more-data",
  });

  // Handle showing success screen for redirect-based integrations.
  useOnDataSourceRedirectSuccessEffect({
    currentRoute: "/inbox",
    openConnectDataSource,
  });

  // TODO(gt): When data sources "syncing" statuses are available, update this
  // to correctly set the "syncing" state. For now, it's always "in_review" state.
  const [currentStatus] = useState<IStatus>("in_review");

  return (
    <View mt={{ "@initial": "20", "@lg": "6", "@md": "1" }}>
      <Flex
        justify="center"
        wrapReverse
        gap={{
          "@initial": "20",
          "@md": "8",
        }}
      >
        <Flex maxWidth="96" direction="column">
          <Text as="h1" size="600">
            Sit tight, we’ll reach out
          </Text>
          <Spacer y="6" />
          <Text as="p" color="contentSecondary">
            Your business is being reviewed. Our review process normally takes 24 hours. Once
            complete, a member of our team will be in touch to review your terms with you and answer
            any questions.
          </Text>
          <Spacer y="6" />
          <View width="48">
            <Button kind="secondary" size="medium" onClick={openConnectDataSource}>
              Add more data
            </Button>
          </View>
          <Spacer y="8" />
          <ContactSupport questionText="Need help?" country={country} />
          <Spacer y="16" />
          <Separator />
        </Flex>
        <IconsFlexWrapper
          align="center"
          justify={{
            "@initial": "center",
            "@lg": "start",
          }}
          gap={{
            "@initial": "16",
            "@sm": "10",
          }}
          pb={{
            "@initial": "20",
            "@lg": "0",
          }}
        >
          {Items.map((item) => {
            const isActive = item.status === currentStatus;
            const CircleComponent = isActive ? GradientCircleWrapper : CircleWrapper;
            const IconComponent = item.getIcon(isActive);

            return (
              <Flex direction="column" justify="center" align="center" gap="4" key={item.status}>
                <CircleComponent>
                  <GradientCircle>{IconComponent}</GradientCircle>
                </CircleComponent>
                <Text color={isActive ? "contentDefault" : "contentSecondary"} whitespace="nowrap">
                  {item.text}
                </Text>
              </Flex>
            );
          })}
        </IconsFlexWrapper>
      </Flex>
    </View>
  );
};

const spinAnimation = keyframes({
  "0%": {
    transform: "rotate(0deg)",
  },
  "50%": {
    transform: "rotate(270deg)",
  },
  "100%": {
    transform: "rotate(0)",
  },
});

const IconsFlexWrapper = styled(Flex, {
  minWidth: t.sizes[96],
});

const GradientCircleWrapper = styled("div", {
  zIndex: 1,
});

const Separator = styled("div", {
  height: t.sizes[1],
  width: t.sizes[12],
  background: t.colors.strokeElevate,
});

const CircleWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: t.sizes[14],
  width: t.sizes[14],
  border: `${t.borderStyles.solid} 2.5px ${t.colors.strokeElevate}`,
  borderRadius: "50%",
});

const GradientCircle = styled("div", {
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "center",

  "width": t.sizes[14],
  "height": t.sizes[14],

  "position": "relative",
  "boxSizing": "border-box",

  "background": t.colors.surfaceElevate,
  "backgroundClip": "padding-box",
  "border": `${t.borderStyles.solid} 2.5px transparent`,
  "borderRadius": "50%",

  "&::before": {
    content: `''`,
    position: "absolute",
    inset: 0,
    zIndex: -1,

    margin: "-2.5px",
    borderRadius: "inherit",

    backgroundImage:
      "linear-gradient(#A6C0FE 0%, rgba(246, 128, 132, 0.3) 38.02%, rgba(249, 220, 220, 0.2) 70.83%, rgba(179, 180, 188, 0.1) 100%)",

    animation: `${spinAnimation} 5000ms linear infinite`,
  },
});
