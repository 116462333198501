import { useTheme } from "@emotion/react";
import React from "react";

import { Text } from "~src/designSystem/atoms/Text";
import { Spacer } from "~src/designSystem/layout/Spacer";
import { ICountryCode } from "~src/shared/types";

import { ContactSupport } from "./ContactSupport";

export type IConnectYourDataProps = {
  country?: ICountryCode;
};

export const ConnectYourData: React.FC<IConnectYourDataProps> = ({ country }) => {
  const theme = useTheme();
  return (
    <>
      <Text as="h1" size="600">
        Connect your data
      </Text>
      <Spacer y="6" />
      <Text
        as="p"
        css={{
          color: theme.components.ConnectYourData.descriptionTextColor,
        }}
      >
        To trade on Pipe, we need to collect some information about your company’s financial health
        and assess your risk score. Your data is never shared, even when you trade.{" "}
      </Text>
      <Spacer y="6" />
      <ContactSupport questionText="Running into issues?" country={country as ICountryCode} />
    </>
  );
};
